// 状态历史表api
import request from '../../../utils/request'
const api_name = '/device-history-status'

export default {
  getAll(data) { //分页查询所有状态表中的数据
    let url = `${api_name}` + '/getAll?number=' + data.currentPage
    if (data.status != '') {
      url += "&handleStatus=" + data.handleStatus
    }
    if (data.type != '') {
      url += "&typeName=" + data.typeName
    }
    if (data.superDeviceId != '') {
      url += "&deviceId=" + data.deviceId
    }
    if (data.superValueName != '') {
      url += "&valueName=" + data.valueName
    }
    if (data.value != "") {
      url += "&value=" + data.value
    }
    if (data.startTime != "" && data.startTime != undefined) {
      url += "&startTime=" + data.startTime
    }
    if (data.endTime != "" && data.endTime != undefined) {
      url += "&endTime=" + data.endTime
    }
    if(data.companyName != ""){
      url += "&companyName=" + data.companyName;
    }
    return request({
      url: url,
      method: 'get'
    })
  },
  getAllHistoryRecord(data) { //单页查询所有状态表中的数据
    let url = `${api_name}` + '/getAllHistoryRecord?number=' + data.currentPage
    // if (data.size != '') {
    //   url += "&size=" + data.size
    // }
    if (data.status != '') {
      url += "&handleStatus=" + data.handleStatus
    }
    if (data.type != '') {
      url += "&typeName=" + data.typeName
    }
    if (data.superDeviceId != '') {
      url += "&deviceId=" + data.deviceId
    }
    if (data.superValueName != '') {
      url += "&valueName=" + data.valueName
    }
    if (data.value != "") {
      url += "&value=" + data.value
    }
    if (data.startTime != "" && data.startTime != undefined) {
      url += "&startTime=" + data.startTime
    }
    if (data.endTime != "" && data.endTime != undefined) {
      url += "&endTime=" + data.endTime
    }
    if(data.companyName != ""){
      url += "&companyName=" + data.companyName;
    }
    
    return request({
      url: url,
      method: 'get'
    })
  },
  //报警历史记录处理
  updateRecord(data){
    return request({
      url: `${api_name}` + '/updateRecord',
      method: 'post',
      data: data,
    })
  },
  getAllHistory(data) {
    return request({
      url: `${api_name}` + '/getAllHistory',
      method: 'post',
      data: data
    })
  },

  getSevenHistoryCounts() {
    return request({
      url: `${api_name}` + '/getSevenHistoryCounts',
      method: 'get',
    })
  },

  // 查全部报警历史记录
  getAllAlarmUntreated(current, limit, deviceStatus) {
    return request({
      url: `${api_name}/getAllAlarmUntreated`,
      method: 'post',
      data: {
        current: current,
        limit: limit,
        deviceId: deviceStatus.deviceId,
        typeId: deviceStatus.typeId,
        ciIds: deviceStatus.ciIds,
        startTime: deviceStatus.startTime,
        endTime: deviceStatus.endTime,
      }
    })
  },

  // 查全部报警历史记录
  getPhoneByQrScanAlarmId(id) {
    return request({
      url: `${api_name}/getPhoneByQrScanAlarmId/${id}`,
      method: 'get',
    })
  },

  // 查全部故障历史记录
  getAllFaultUntreated(current, limit, deviceStatus) {
    return request({
      url: `${api_name}/getAllFaultUntreated`,
      method: 'post',
      data: {
        current: current,
        limit: limit,
        deviceId: deviceStatus.deviceId,
        typeId: deviceStatus.typeId,
        ciIds: deviceStatus.ciIds,
        startTime: deviceStatus.startTime,
        endTime: deviceStatus.endTime,
      }
    })
  },

  // 根据公司id查设备
  getCompanyDevice(ciId) {
    return request({
      url: `${api_name}/getCompanyDevice`,
      method: 'post',
      data: {
        ciId: ciId,
      }
    })
  },

  // 查所有上报人姓名
  getToDistributionPersonList() {
    return request({
      url: `${api_name}/getToDistributionPersonList`,
      method: 'post',
    })
  },

  // 提交上报信息
  sendAlarmInfoForPerson(wechatAlarmVo) {
    return request({
      url: `${api_name}/sendAlarmInfoForPerson`,
      method: 'post',
      data: wechatAlarmVo
    })
  }


}