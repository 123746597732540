import request from '../../../utils/request'
const api_name = '/iotechserver/deviceType'

export default {
  getDeviceTypeList(current, limit, deviceTypeQuery) {
    return request({
      url: `${api_name}/pageDeviceType/${current}/${limit}?name=` + deviceTypeQuery.name,
      method: 'get',
    })
  },
  addDeviceType(formData) {
    return request({
      headers: {
        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'
      },
      url: `${api_name}/addDeviceType`,
      method: 'post',
      data: formData
    })
  },
  removeDeviceType(id) {
    return request({
      url: `${api_name}/removeDeviceType/${id}`,
      method: 'delete',
    })
  },
  updateDeviceType(formData) {
    return request({
      headers: {
        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'
      },
      url: `${api_name}/updateDeviceType`,
      method: 'post',
      data: formData
    })
  },
  getAllDeviceType(deviceTypeInfo) {
    return request({
      url: `${api_name}/getAllDeviceType`,
      method: 'get',
      data: deviceTypeInfo
    })
  },
  getDeviceInfoByTypeId(typeId) {
    return request({
      url: `${api_name}/getDeviceInfoByTypeId/${typeId}`,
      method: 'get'
    })
  },


  // 查全部设备类型
  getAllDeviceType() {
    return request({
      url: `${api_name}/getAllDeviceType`,
      method: 'get'
    })
  },

  // 根据公司查设备类型
  getDeviceTypeByCiIds(id) {
    return request({
      url: `${api_name}/getDeviceTypeByCiIds`,
      method: 'post',
      data: {
        name: id,
      }
    })
  },

  // 添加设备类型 
  addDeviceTypeByCiId(ciId, valueList) {
    return request({
      url: `${api_name}/addDeviceTypeByCiId`,
      method: 'post',
      data: {
        name: ciId,
        valueList: valueList,
      }
    })
  },

  // 删除设备类型
  deleteDeviceTypeByCiId(ciId, valueList) {
    return request({
      url: `${api_name}/deleteDeviceTypeByCiId`,
      method: 'post',
      data: {
        name: ciId,
        valueList: valueList,
      }
    })
  },

  // 通过账号查询绑定公司下的设备类型
  getDeviceTypeByUser() {
    return request({
      url: `${api_name}/getDeviceTypeByUser`,
      method: 'get',
    })
  },

  //查询设备类型的静态属性
  getStaticPro(typeId) {
    return request({
      url: `/iotechserver/deviceTypeProAndVal/getStaticPro`,
      method: 'post',
      data: {
        typeId: typeId
      }
    })
  },

  // 通过设备id查类型
  getDeviceTypeById(id) {
    return request({
      url: `${api_name}/getDeviceTypeById/${id}`,
      method: 'get',
    })
  },



  // 设备类型分类 #####################
  // 分页查询设备类型分类
  pageDeviceTypeClassify(current, limit) {
    return request({
      url: `${api_name}/pageDeviceTypeClassify/${current}/${limit}`,
      method: 'get',
    })
  },

  // 添加/编辑设备类型分类
  addDeviceTypeClassify(list) {
    return request({
      url: `${api_name}/addDeviceTypeClassify`,
      method: 'post',
      data: list
    })
  },

  // 回显
  getBindDeviceTypeByClassifyName(name) {
    return request({
      url: `${api_name}/getBindDeviceTypeByClassifyName`,
      method: 'post',
      data: {
        name: name
      }
    })
  },

    // 删除
    deleteDeviceClassify(name) {
    return request({
      url: `${api_name}/deleteDeviceClassify`,
      method: 'post',
      data: {
        name: name
      }
    })
  },

  // 查询未添加的设备类型
  getNoBindDeviceTypeList() {
    return request({
      url: `${api_name}/getNoBindDeviceTypeList`,
      method: 'get',
    })
  },

  // 设备管理，添加、删除成功后回调
  updateDeviceNumber(id, typeId, ciId, type) {
    return request({
      url: `${api_name}/updateDeviceNumber`,
      method: 'post',
      data:{
        id: id,
        typeId: typeId,
        ciId: ciId,
        type: type,
      }
    })
  },

  // 初始化
  startDeviceNumber() {
    return request({
      url: `${api_name}/startDeviceNumber`,
      method: 'get',
    })
  },
  
}