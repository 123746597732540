<template>
  <div id="app">
    <el-row>
      <el-col class="pl32 company" :span="8">
        <span>{{ headerInfo.company }}</span>
      </el-col>

      <el-col class="lh-tc mainTitle" :span="8">
        <span>{{ headerInfo.title }}</span>
      </el-col>

      <el-col class="lh-12" :span="7">{{ headerInfo.time }}</el-col>
      <el-col :span="1">
        <div class="iconfont icon-iotechfanhui" @click="exit" style="color:aqua"></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import user from "@/api/manageApi/User";
import AccountManage from '@/api/manageApi/AccountManage'

export default {
  data() {
    return {
      headerInfo: {
        // company: "江苏河马自动化设备有限公司",   // 江苏河马
        // title: "消防云平台管理系统",     // 江苏河马

        // company: "以科技前瞻 · 赢安全未来",   // 爱欧科技
        // title: "消防云平台管理系统",   // 爱欧科技

        // company: "黔安云+ 综合安全管理平台",
        // title: "综合安全管理平台",

        company: '',
        title: '',

        time: "",
      },
      timer: null,

      users: '',
      platformInfo: [],

    }

  },


  created() {
    this.getUserInfo()
    this.getRealTime()
    this.timer = setInterval(() => {
      this.getRealTime()
    }, 1000);
  },

  methods: {
    getRealTime() {
      this.headerInfo.time = this.$moment().format("LLL");
    },

    exit() {
      this.$router.push('/Select')
    },

    async getUserInfo() {
      const { data: res } = await user.loginUserInfo()
      this.users = res.data.userName
      this.getTitleText()
    },

    getTitleText() {
      AccountManage.getShowsByUserName(this.users).then(res => {
        this.platformInfo = res.data.data.data
        if (this.platformInfo.platformSlogan == undefined) {
          this.headerInfo.company = '以科技前瞻 · 赢安全未来';
        } else {
          this.headerInfo.company = this.platformInfo.platformSlogan
        }
        if (this.platformInfo.platformName == undefined) {
          this.headerInfo.title = '消防物联网监控平台';
        } else {
          this.headerInfo.title = this.platformInfo.platformName
        }

      })
    },


  },

  beforeDestroy() {
    clearInterval(this.timer)
  }

}
</script>

<style lang="scss" scoped>
.pl32 {
  text-align: left;
  font-size: 15px;
}

.lh-tc {
  font-weight: 500;
  line-height: 80px;
  text-align: center;
}

.company {
  color: aqua;
  font-size: 28px;
  line-height: 80px;
  letter-spacing: 6px;
  text-shadow: 4px 4px 3px #000;
  padding-left: 10px;
}

.mainTitle {
  color: rgb(7, 228, 228);
  font-weight: 700;
  font-size: 45px;
  letter-spacing: 5px;
  font-family: "宋体";
  text-shadow: 5px 5px 3px #000;
}

/deep/ .lh-12 {
  line-height: 80px;
  text-align: right;
  width: 28%;
  color: aqua;
  font-size: 25px !important;
  line-height: 80px;
  text-shadow: 4px 4px 3px #000;
}

.iconfont {
  cursor: pointer;
  //   color: lightgreen;
  font-size: 26px;
  line-height: 80px;
  text-align: right;
  //   animation: breathing 1s linear infinite alternate;
}</style>