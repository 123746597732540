import request from '@/../utils/request'
const api_name = '/iotechserver/homePage'

export default {
  wirelessSmokeDetector(deviceName, current, limit) {
    return request({
      url: `${api_name}/wirelessSmokeDetector/${current}/${limit}`,
      method: 'post',
      data: deviceName
    })
  },

  dynamicQuery(deviceId) {
    return request({
      url: `${api_name}/dynamicQuery/${deviceId}`,
      method: 'get',
    })
  },

  viewVideo(deviceId) {
    return request({
      url: `${api_name}/viewVideo/${deviceId}`,
      method: 'get',
    })
  },

  pageDeviceInfoByTypeName(classify, current, limit) {
    return request({
      url: `${api_name}/pageDeviceInfoByTypeName/${current}/${limit}?classifyName=${classify}`,
      method: 'get',
    })
  },

  //查询报警的设备
  getAllAlarmDevice() {
    return request({
      url: `${api_name}/getAllAlarmDevice`,
      method: 'get',
    })
  },
  // 报警设备新写接口分页查
  getAbnormalDeviceList(alarmType, current, limit) {
    return request({
      url: `${api_name}/getAbnormalDeviceList/${alarmType}/${current}/${limit}`,
      method: 'get',
    })
  },
  //查询失联的设备
  getAlllostInDevice() {
    return request({
      url: `${api_name}/getAllLostInDevice`,
      method: 'get',
    })
  },
  //查询故障的设备
  getAllfaultDevice() {
    return request({
      url: `${api_name}/getAllfaultDevice`,
      method: 'get',
    })
  },

  // 处理单个设备
  getAllHistory() {
    return request({
      url: `${api_name}/getAllHistory`,
      method: 'get',
    })
  },

  //查询设备绑定视屏的url
  getVideoUrl(deviceId) {
    return request({
      url: `${api_name}/getVideoUrl/${deviceId}`,
      method: 'get',
    })
  },

  // 设备状态和设备
  getAbnormalCiIdList() {
    return request({
      url: `${api_name}/getAbnormalCiIdList`,
      method: 'get',
    })
  },

  // 查单位下的设备总数
  getCiIdDeviceNumber(){
    return request({
      url: `${api_name}/getCiIdDeviceNumber`,
      method: 'get',
    })
  },

  // 查询单位下的设备名称
  getDeviceListByCiId(ciId, current, limit){
    return request({
      url: `${api_name}/getDeviceListByCiId/${ciId}/${current}/${limit}`,
      method: 'get',
    })
  }




}