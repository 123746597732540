import request from '@/../utils/request'
const api_name = '/iotechserver/notifybigsystem'

export default {

    // 查询报警系统列表
    getNotifyBigSystemById() {
        return request({
            url: `${api_name}/getNotifyBigSystemById`,
            method: 'get',
        })
    },
    // 添加 报警系统
    addNotifyBigSystem(bigSystemName) {
        return request({
            url: `${api_name}/addNotifyBigSystem`,
            method: 'post',
            data: { bigSystemName: bigSystemName },
        })
    },

    // 删除
    deleteNotifyBigSystem(id) {
        return request({
            url: `${api_name}/deleteNotifyBigSystem/${id}`,
            method: 'delete',
        })
    },


}