<template>
  <div id="app">
    <!-- AlarmAcceptance -->
    <!-- <el-row>
      <el-col :span="24">
        <el-page-header @back="goSelect" content="告警受理" style="margin:10px 0">
        </el-page-header>
      </el-col>
    </el-row> -->
    <el-header class="header-bg bgclor" style="height: 80px;width:100%;position:absolute;top:0px">
      <show-header></show-header>
    </el-header>

    <div class="container">
      <div class="left">
        <el-input placeholder="输入关键字进行搜索..." prefix-icon="el-icon-search" v-model="filterText"
          style="margin: 0 10px 5px 0; width: 100%">
        </el-input>
        <div class="left-content-top">
          <div class="type-title">
            <span class="title" v-if="isFlag">
              <span>当前单位</span>
              <span style="float:right" @click="flagHandle">
                <i class="icon-iotechqiehuan iconfont" style="font-size:22px;font-weight:500" title="查看全部单位"></i>
              </span>
            </span>
            <span class="title" v-else>
              <span>单位列表</span>
              <span style="float:right" @click="flagHandle">
                <i class="icon-iotechqiehuan iconfont" style="font-size:22px;font-weight:500;cursor:pointer"
                  :title="companyName"></i>
              </span>
            </span>
          </div>
          <dv-decoration-1 style="width:300px;height:15px;" :color="['lightblue']" />
          <div v-if="isFlag" style="color:#0cc7ce;font-size:16px;padding:20px;font-weight:600">
            {{ companyName }}
          </div>
          <div v-else>
            <el-tree :check-strictly="true" :data="companyList" :props="defaultProps" default-expand-all node-key="id"
              :filter-node-method="filterNode" :expand-on-click-node="false" show-checkbox @check="getPickCompant"
              ref="tree">
              <span slot-scope="{ node, data }">
                <span :title="node.label">{{ node.label }}</span>
              </span>
            </el-tree>
          </div>

        </div>
        <div class="left-content-btm">

          <div class="type-title">
            <span class="title">{{ thisDevice.title }}</span>
          </div>
          <dv-decoration-1 style="width:300px;height:15px;" :color="['lightblue']" />
          <!-- 设备图片 -->
          <dv-border-box-2 class="img-box" :color="['#008c8c', 'green']">
            <div class="img-cont">
              <el-image class="img" style="height:84%;" :src="deviceImg" :preview-src-list="deviceImgList">
                <div slot="error" class="img-error">
                  <div class="img-error">图片未上传!</div>
                </div>
              </el-image>
            </div>
          </dv-border-box-2>
          <!-- 设备描述 -->
          <!-- <el-descriptions :column="1" border size="small"> -->
          <div v-if="processList.length != 0">
            <div style="height:160px;overflow:scroll;color:#80ecf0" v-if="processList.typeId.indexOf('QrCode') != -1">
              <div style="height:30px;line-height:30px;padding:5px"><span>设备编号：</span>{{ processList.deviceId }}</div>
              <div style="height:30px;line-height:30px;padding:5px"><span>安装位置：</span>{{ processList.note }}</div>
              <div style="height:30px;line-height:30px;padding:5px"><span>上报人手机号：</span>{{ processList.notifySystemId }}
              </div>
              <div style="height:30px;line-height:30px;padding:5px"><span>单位名称：</span>{{ processList.companyId }}</div>
            </div>
            <div style="height:160px;overflow:scroll;color:#80ecf0" v-else>
              <div style="height:30px;line-height:30px;padding:5px;overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;"><span>设备编号：</span>{{ deviceInfos.id }}</div>
              <!-- <div style="height:30px;line-height:30px;padding:5px" v-if="undefd"><span>设备类型：</span>{{ rowInfo.typeName }}
              </div> -->
              <div style="height:30px;line-height:30px;padding:5px" v-if="undefd"><span>单位：</span>{{ rowInfo.companyId }}
              </div>
              <div style="height:30px;line-height:30px;padding:5px" v-if="showQrCodePhone">
                <span>上报人电话：</span>{{ qrCodePhone }}
              </div>
              <div style="height:30px;line-height:30px;padding:5px" v-if="undefd"><span>报警时间：</span>{{ rowInfo.createTime
              }}
              </div>
              <div style="height:30px;line-height:30px;padding:5px" v-for="(item, index) in deviceInfo"
                v-if="(item.name.indexOf('安装位置') != -1) || (item.name.indexOf('单位') != -1)">
                <span>{{ item.name }}：</span>{{ item.value }}
              </div>
            </div>
          </div>

          <div style="margin-top:10px" v-if="processList.length != 0">
            <el-button @click="showVideo()" type="warning" size="mini">视频</el-button>
            <el-dialog title="处理异常设备" :visible.sync="visible" width="30%" style="background:rgba(0,0,0,0.5)">
              <div>
                <div style="width:100%">
                  <span style="font-size:16px">选择处理类型：</span>
                  <el-radio-group v-model="processRadio">
                    <el-radio :label="1" style="margin:10px">真实</el-radio>
                    <el-radio :label="2" style="margin:10px">误报</el-radio>
                    <el-radio :label="3" style="margin:10px">检修</el-radio>
                    <el-radio :label="4" style="margin:10px">测试</el-radio>
                  </el-radio-group>
                  <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea">
                  </el-input>
                </div>
              </div>
              <div slot="footer">
                <el-button @click="visible = false">取 消</el-button>
                <el-button type="primary" @click="handleClick">确 定</el-button>
              </div>
            </el-dialog>
            <el-button slot="reference" type="success" size="mini" style="margin-left:10px"
              v-if="userRightList.updateJuris == '1'" @click="processOne">处理</el-button>
            <el-button @click="toCRT()" type="primary" size="mini" style="">CRT</el-button>

            <el-dialog title="上报问题" :visible.sync="showAllocation" width="30%" style="background:rgba(0,0,0,0.5)">
              <div class="assign-box">
                <div style="display:flex">
                  <div style="display:inline-block;width:30%;margin:10px 0;font-weight:600">选择分配人员</div>
                  <el-select v-model="selectName" placeholder="请选择姓名" clearable style="background:none;width:60%"
                    filterable @change="getName">
                    <el-option v-for="item in nameList" :key="item.phone" :label="item.name" :value="item">
                    </el-option>
                  </el-select>
                  <div style="width:10%;padding:6px 0">
                    <el-button type="primary" icon="el-icon-plus" size="mini" style="display:inline-block;float:right"
                      circle @click="sendInfoInput"></el-button>
                  </div>
                </div>

                <div style="display:flex;margin:10px 0">
                  <div style="display:inline-block;width:30%;margin:10px 0;font-weight:600">选择通知方式</div>
                  <el-checkbox-group v-model="sendInfo" size="small" :disabled="selectName == ''"
                    @change="sendInfoHandler">
                    <el-checkbox-button v-for="item in sendInfoList" :label="item" :key="item">{{ item
                    }}</el-checkbox-button>
                  </el-checkbox-group>
                </div>
                <hr v-if="allocationList.length != 0">
                <div>
                  <div v-for="(item, index) in allocationList" :key="index" class="del">
                    <span style="display:inline-block;width:30%"><b>姓名：</b>{{ item.name }}</span>
                    <span style="display:inline-block;width:60%">
                      <b>通知方式：</b>
                      <span v-if="item.sendWechat != 0">微信、</span>
                      <span v-if="item.sendMessage != 0">短信、</span>
                      <span v-if="item.sendPhone != 0">电话、</span>
                    </span>
                    <span>
                      <i class="el-icon-close del-item" @click="delItem(index)"></i>
                    </span>
                  </div>
                </div>
              </div>

              <div slot="footer">
                <el-button @click="showAllocation = false">取 消</el-button>
                <el-button type="primary" @click="submitAllocation">确 定</el-button>
              </div>
            </el-dialog>
            <el-button slot="reference" @click="allocation" type="success" size="mini"
              v-if="userRightList.updateJuris == '1'" style="margin-left:10px;">分配</el-button>
          </div>
          <div v-else style="text-align:center;margin-top:100px;color:#999;font-size:16px;letter-spacing: 2px">
            暂无数据
          </div>

        </div>
      </div>

      <div class="right">
        <!-- 报警设备列表 -->

        <!-- 条件查询  -->
        <div>
          <el-row>
            <el-col :span="4" style="margin-right: 10px;">
              <el-input v-model="deviceStatus.deviceId" :clearable="true" placeholder="请输入设备号"></el-input>
            </el-col>

            <el-col :span="4">
              <el-select v-model="deviceStatus.typeId" style="width: 100%;" filterable clearable placeholder="请选择设备类型">
                <el-option v-for="item in deviceTypeList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-col>


            <el-col :span="5">
              <el-date-picker v-model="pickedTimes" type="datetimerange" @change="pickedTime" start-placeholder="开始日期"
                end-placeholder="结束日期" clearable :default-time="['12:00:00']" style="margin:0 10px;width: 100%;">
              </el-date-picker>
            </el-col>

            <el-col :span="1.5" style="margin-left: 20px;">
              <el-button icon="el-icon-search" type="primary" @click="queryInformation"
                style="margin-left:5px">查询</el-button>
              <!-- <el-button icon="el-icon-check" type="success" @click="aKeyProcess"
                v-if="userRightList.updateJuris == '1'">一键处理</el-button> -->
            </el-col>

            
            <el-col :span="4" style="margin-left: 20px;">
              <!-- <el-button icon="el-icon-search" type="primary" @click="queryInformation"
                style="margin-left:5px">查询</el-button> -->
              <el-button icon="el-icon-check" type="success" @click="aKeyProcess"
                v-if="userRightList.updateJuris == '1'">一键处理</el-button>
            </el-col>

            <el-col :span="4" style="float: right;">
              <el-select v-model="tableTitle" multiple collapse-tags placeholder="选择类型" @change="selectTitle">
                <el-option v-for="item in tableTitleList" :key="item.value" :label="item.title" :value="item.title">
                </el-option>
              </el-select>
            </el-col>

          </el-row>
        </div>

        <div class="content1">
          <!-- 表格  highlight-current-row-->
          <div class="content1-item">
            <div class="type-title">
              <span class="title">报警设备信息</span>
              <!-- <dv-decoration-3 style="width:250px;height:30px;" /> -->
              <!-- <el-col :span="6" style="float:right;width:13%;margin:10px 0px">
                <el-select v-model="tableTitle" multiple collapse-tags placeholder="选择类型" @change="selectTitle">
                  <el-option v-for="item in tableTitleList" :key="item.value" :label="item.title" :value="item.title">
                  </el-option>
                </el-select> -->
              <!-- </el-col> -->
              <dv-decoration-3 style="width:260px;height:10px;" />
            </div>
            <el-table :data="alarmList" :max-height="height" style="width: 100%;cursor:pointer" @row-click="rowClick"
              :row-style="rowStyle" :highlight-current-row="row1"
              @selection-change="handleSelectionChange('top', $event)">
              <el-table-column type="selection" width="55">
              </el-table-column>
              <el-table-column fixed="left" type="index" label="序号" width="55" align="center">
              </el-table-column>
              <el-table-column v-if="tableTitle.includes('设备编号')" prop="deviceId" label="设备编号" align="center">
              </el-table-column>
              <el-table-column v-if="tableTitle.includes('设备类型')" prop="typeName" label="设备类型" align="center">
              </el-table-column>
              <el-table-column v-if="tableTitle.includes('单位')" prop="companyId" label="单位" align="center">
              </el-table-column>
              <el-table-column v-if="tableTitle.includes('报警类型')" label="报警类型" align="center">
                <template slot-scope="scope">
                  <span
                    :style="scope.row.value.indexOf('警') != -1 ? 'color:#b81a35;font-weight:600' : 'color:#b81a35;font-weight:600'">{{
                      scope.row.value }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="tableTitle.includes('处理说明')" prop="process" label="处理说明" align="center">
              </el-table-column>
              <el-table-column v-if="tableTitle.includes('处理类型')" prop="processType" label="处理类型" align="center">
              </el-table-column>
              <el-table-column v-if="tableTitle.includes('报警时间')" prop="createTime" label="报警时间" align="center">
              </el-table-column>
              <el-table-column v-if="tableTitle.includes('处理时间')" prop="checkTime" label="处理时间" align="center">
              </el-table-column>

              <el-table-column v-if="tableTitle.includes('处理状态')" label="处理状态" align="center">
                <template slot-scope="scope">
                  <el-tag effect="dark" size="mini"
                    :type="scope.row.handleStatus.indexOf('已处理') != -1 || scope.row.handleStatus.indexOf('自动恢复') != -1 ? 'success' : 'warning'">{{
                      scope.row.handleStatus }}</el-tag>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <!-- 分页 -->
          <div class="content1-item1">
            <el-row class="row">
              <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, rem. -->
              <el-pagination :background="true" @current-change="pagination('alarm')" :current-page.sync="alarmCurrent"
                :page-size="alarmLimit" layout="total, prev, pager, next , jumper" :total="alarmTotal">
              </el-pagination>
            </el-row>
          </div>
        </div>

        <!-- <dv-border-box-2 class="content1"> -->
        <!-- 故障设备列表 highlight-current-row-->
        <el-row class="content1">
          <div class="content1-item">
            <div class="type-title">
              <span class="title">故障设备信息</span>
              <!-- <el-col :span="6" style="float:right;width:13%;margin:10px 0px">
                <el-select v-model="tableTitle1" multiple collapse-tags placeholder="选择类型" @change="selectTitle1">
                  <el-option v-for="item in tableTitleList1" :key="item.value" :label="item.title" :value="item.title">
                  </el-option>
                </el-select>
              </el-col> -->
              <dv-decoration-3 style="width:260px;height:10px;" />
            </div>
            <el-table :data="faultList" :max-height="height" style="width: 100%;cursor:pointer" @row-click="rowClick"
              :row-style="rowStyle" :highlight-current-row="row2"
              @selection-change="handleSelectionChange('btm', $event)">
              <el-table-column type="selection" width="55">
              </el-table-column>
              <el-table-column fixed="left" type="index" label="序号" width="55" align="center">
              </el-table-column>
              <el-table-column v-if="tableTitle.includes('设备编号')" prop="deviceId" label="设备编号" align="center">
              </el-table-column>
              <el-table-column v-if="tableTitle.includes('设备类型')" prop="typeName" label="设备类型" align="center">
              </el-table-column>
              <el-table-column v-if="tableTitle.includes('单位')" prop="companyId" label="单位" align="center">
              </el-table-column>
              <!-- <el-table-column prop="note" label="安装位置" align="center" width="200">
              </el-table-column> -->

              <el-table-column v-if="tableTitle.includes('报警类型')" label="报警类型" align="center">
                <template slot-scope="scope">
                  <span
                    :style="scope.row.value.indexOf('失联') != -1 ? 'color:#dcdde1;font-weight:600' : scope.row.value.indexOf('故障') != -1 ? 'color:#ffbe76;font-weight:600' : ''">{{
                      scope.row.value }}</span>
                </template>
              </el-table-column>

              <el-table-column v-if="tableTitle.includes('处理说明')" prop="process" label="处理说明" align="center">
              </el-table-column>
              <el-table-column v-if="tableTitle.includes('处理类型')" prop="processType" label="处理类型" align="center">
              </el-table-column>
              <el-table-column v-if="tableTitle.includes('报警时间')" prop="createTime" label="报警时间" align="center">
              </el-table-column>
              <el-table-column v-if="tableTitle.includes('处理时间')" prop="checkTime" label="处理时间" align="center">
              </el-table-column>

              <el-table-column v-if="tableTitle.includes('处理状态')" label="处理状态" align="center">
                <template slot-scope="scope">
                  <el-tag size="mini" effect="dark"
                    :type="scope.row.handleStatus.indexOf('已处理') != -1 || scope.row.handleStatus.indexOf('自动恢复') != -1 ? 'success' : 'warning'">{{
                      scope.row.handleStatus }}</el-tag>
                </template>
              </el-table-column>

            </el-table>
          </div>
          <div class="content1-item1">
            <el-row class="row">
              <el-pagination :background="true" @current-change="pagination('fault')" :current-page.sync="faultCurrent"
                :page-size="faultLimit" layout="total, prev, pager, next , jumper" :total="faultTotal">
              </el-pagination>
            </el-row>
          </div>

        </el-row>
        <!-- </dv-border-box-2> -->
      </div>

    </div>
    <!-- 播放视频弹窗 -->
    <el-dialog title="视频" :visible.sync="playerDialogVisible" :destroy-on-close=true width="1280px" top="7vh"
      style="background:rgba(0,0,0,0.5)">
      <div class="videoClass" style="height:75.5vh;">
        <cus-player ref="video0"></cus-player>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import ShowHeader from '@/components/deviceClass/ShowHeader'

import DeviceTypeManagement from "@/api/manageApi/DeviceTypeManagement";
import CompanyInfo from "@/api/managementApi/CompanyInfo";
import CompanyMessage from "@/api/managementApi/CompanyMessage"
import DeviceHistoryStatus from '@/api/manageApi/DeviceHistoryStatus'
import companySystem from "@/api/managementApi/CompanyInfo";
import noticeSysManagement from "@/api/managementApi/NoticeSysManagement";
import DeviceInfoManagement from '@/api/manageApi/DeviceInfoManagement'
import crtDeviceCoordinate from "@/api/manageApi/CrtDeviceCoordinate";
import BaseData from '@/assets/config/BaseData'
import HomePage from "@/api/manageApi/HomePage";
import deviceInfo from "../../api/manageApi/DeviceInfoManagement";    //包含查询绑定视频的方法
// 查权限
import LoginUserJuris from '@/api/managementApi/LoginUserJuris'
// 查账号
import user from "@/api/manageApi/User";

//视频组件
import CusPlayer from '../videos/CusPlayer.vue'
import FlvJsPlayer from "xgplayer-flv.js";
import Player from "xgplayer";
import { v4 } from 'uuid';

export default {
  components: {
    ShowHeader,
    CusPlayer,
  },
  data() {
    return {
      isFlag: false,
      playerDialogVisible: false, //视频播放器标志位,是否展示视频弹窗
      playUrl: '',
      companyName: '',
      addedList: [],  // 上报问题中已添加的人员
      sendInfoList: ['微信', '短信', '电话'],
      sendInfo: [],
      sendInfoObj: {},
      // 分派任务名单
      nameList: [],
      selectName: '', // 选择的人名
      selectNameList: [], // 选择的人名
      allocationList: [], // 分配人信息

      tableTitleList: [{
        id: 0,
        title: '设备编号',
      }, {
        id: 1,
        title: '设备类型',
      }, {
        id: 2,
        title: '单位',
      }, {
        id: 3,
        title: '报警类型',
      }, {
        id: 4,
        title: '处理说明',
      }, {
        id: 5,
        title: '处理类型',
      }, {
        id: 6,
        title: '报警时间',
      }, {
        id: 7,
        title: '处理时间',
      }, {
        id: 8,
        title: '处理状态',
      }],
      tableTitle: ['设备编号', '设备类型', '单位', '报警类型', '处理说明', '处理类型', '报警时间', '处理时间', '处理状态'],

      tableTitleList1: [{
        id: 0,
        title: '设备编号',
      }, {
        id: 1,
        title: '设备类型',
      }, {
        id: 2,
        title: '单位',
      }, {
        id: 3,
        title: '报警类型',
      }, {
        id: 4,
        title: '处理说明',
      }, {
        id: 5,
        title: '处理类型',
      }, {
        id: 6,
        title: '报警时间',
      }, {
        id: 7,
        title: '处理时间',
      }, {
        id: 8,
        title: '处理状态',
      }],
      tableTitle1: ['设备编号', '设备类型', '单位', '报警类型', '处理说明', '处理类型', '报警时间', '处理时间', '处理状态'],

      row1: true,
      row2: true,
      textarea: '',
      // 当前设备状态
      currentStatus: '',
      baseUrl: BaseData.baseUrl,

      showAllocation: false,

      // 设备图片
      deviceImg: '',
      deviceImgList: [],
      height: 350,
      num: 0,
      // 处理类型
      processRadio: '',
      visible: false,
      processList: [],
      // 点击行获取的设备信息
      thisDevice: {
        id: '',
        title: '',
      },
      // 根据id查的设备信息集合
      deviceInfo: [],
      deviceInfos: [],

      // 公司树形结构绑定
      defaultProps: {
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
      },

      pickedTimes: [],

      filterText: '',   // 搜索公司

      companyList: [],  // 公司列表
      deviceTypeList: [],   // 设备类型列表

      allCompanyList: [],  // 所有公司
      allSystemList: [],  // 所有系统

      alarmTotal: 0,
      faultTotal: 0,

      deviceStatus: {
        deviceId: '', // 设备id
        typeId: '', // 类型 id
        companyId: '',  // 公司 id
        ciIds: [],
        startTime: '',  // 开始时间
        endTime: '' // 结束时间
      },

      // 报警 ****************
      deviceIdOfAlarme: '',
      deviceTypeOfAlarme: '',
      statusOfAlarme: '',
      typeNameOfAlarme: '',
      alarmList: [],
      alarmCurrent: 1,
      alarmLimit: 10,



      alarmOrfaultDeviceId: '',

      // 故障*****************
      deviceIdOfFault: '',
      deviceTypeOfFault: '',
      statusOfFault: '',
      typeNameOfFault: '',
      faultList: [],
      faultCurrent: 1,
      faultLimit: 10,

      KeyDown: false,
      checkedKeys: [],

      rowInfo: [],
      thisTypeId: '',
      undefd: false,

      userRightList: [],
      qrCodePhone: '',
      showQrCodePhone: false,
      deviceIds: '',
      // 复选框处理
      alarmObj: {
        name: '',
        value: []
      },
      faultObj: {
        name: '',
        value: []
      },

      symbol: '',

      total: [],
      idStr: '',
    }
  },

  created() {
    this.getAllCompanyAndSystem().then(res => {
      if (this.$route.query.companyId) {
        this.checkedKeys.push(this.$route.query.companyId)
        this.isFlag = true

        //   this.getAlldevice();
        this.init()
        this.getPickCompant()
      } else if (this.$route.query.deviceId) {
        this.alarmOrfaultDeviceId = this.$route.query.deviceId;
        this.init()
        this.getAlldevice()
      } else {
        this.init()
        this.getAlldevice();
      }
    })
  },


  watch: {
    // 利用watch方法检测路由变化：
    '$route': function (to, from) {
      // 拿到目标参数 to.query.id 去再次请求数据接口
      if (to.query.randomId) {
        this.getAlldevice()
      }
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },

  methods: {
    init() {
      this.getUsers()
      this.getAllDeviceType();
      this.getCompanyStructure();
    },


    handleSelectionChange(params, value) {
      // console.log(params, value);
      if (params == 'top') {
        // for (let i = 0; i < value.length; i++) {
        //   this.total.push(value[i].id)
        // }
        // console.log(this.total);
        this.alarmObj.name = params
        this.alarmObj.value = value
      } else {
        this.faultObj.name = params
        this.faultObj.value = value
      }
    },
    processOne() {
      this.visible = true
      this.symbol = '处理'
    },

    flagHandle() {
      console.log(this.$route.query.companyId);
      if (this.$route.query.companyId != undefined) {
        if (this.isFlag) {
          this.isFlag = !this.isFlag
          this.deviceStatus.ciIds = []
          this.getAlldevice()
        } else {
          this.isFlag = !this.isFlag
          this.deviceStatus.ciIds.push(this.$route.query.companyId)
          this.getAlldevice()
        }
      }
    },

    allocation() {
      if (this.rowInfo.id == '' || this.rowInfo.id == null || this.rowInfo.id == undefined) {
        this.$message.warning('请选择设备！')
      } else if (this.rowInfo.handleStatus == "已处理") {
        this.$message.warning("此设备已处理，无法分配！")
      } else {
        this.showAllocation = true
      }
    },


    // 选择的上报人
    getName(value) {
      // let flag = false
      if (this.addedList.includes(value.phone)) {
        this.selectName = ''
        this.$message.warning("该人员已被分配，请勿重复添加！")
      } else {
        this.selectName = value.name
        this.selectNameList = value
      }
    },

    // 选择通知方式
    sendInfoHandler(value) {
      let obj = {}
      if (value.length != 0) {
        obj.phone = this.selectNameList.phone
        obj.name = this.selectNameList.name
        value.includes('微信') ? obj.sendWechat = 1 : obj.sendWechat = 0
        value.includes('短信') ? obj.sendMessage = 1 : obj.sendMessage = 0
        value.includes('电话') ? obj.sendPhone = 1 : obj.sendPhone = 0
      } else {
        this.sendInfo.push('微信')
        this.$message.warning('至少选择一种通知方式！')
        obj.phone = this.selectNameList.phone
        obj.name = this.selectNameList.name
        value.includes('微信') ? obj.sendWechat = 1 : obj.sendWechat = 0
        value.includes('短信') ? obj.sendMessage = 1 : obj.sendMessage = 0
        value.includes('电话') ? obj.sendPhone = 1 : obj.sendPhone = 0
      }
      this.sendInfoObj = obj
    },

    delItem(index) {
      this.allocationList.splice(index, 1)
      this.addedList.splice(index, 1)
    },

    // 添加一组人员及通知方式
    sendInfoInput() {
      if (this.selectName == '') {
        this.$message.warning("请选择分配人员!")
      } else if (this.sendInfo.length == 0) {
        this.$message.warning("请选择通知方式!")
      } else {
        this.allocationList.push(this.sendInfoObj)
        this.addedList.push(this.sendInfoObj.phone)
        this.selectName = ''
        this.sendInfo = []
      }
    },
    selectTitle(value) {
      if (value.length < 1) {
        this.$message.warning("至少选择1个类型")
        this.tableTitle.push('设备编号')
      } else {
        this.tableTitle = value
      }
    },
    selectTitle1(value) {
      if (value.length < 1) {
        this.$message.warning("至少选择1个类型")
        this.tableTitle1.push('设备编号')
      } else {
        this.tableTitle1 = value
      }
    },
    // 分配
    async submitAllocation() {
      if (this.allocationList.length == 0) {
        this.$message.warning('提交内容不能为空！请检查选择人员是否添加。')
      } else {
        let wechatAlarmVo = {}
        wechatAlarmVo.id = this.rowInfo.id
        wechatAlarmVo.sendMethodVoList = this.allocationList

        const { data: res } = await DeviceHistoryStatus.sendAlarmInfoForPerson(wechatAlarmVo)
        if (res.message == "分配成功") {
          this.$message.success('分配成功!')
        }
        this.showAllocation = false
      }
    },

    // 获取用户权限
    async getUserRight(user) {
      this.userRightList = []
      const { data: res } = await LoginUserJuris.getMenuFromUserName(user)
      this.userRightList = res.data.jurisList
      if (this.userRightList.length == 0) {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      } else {
        for (let i = 0; i < res.data.jurisList.length; i++) {
          if (res.data.jurisList[i].menuName == "AlarmAcceptance") {
            this.userRightList = res.data.jurisList[i]
          }
        }
      }
      if (user == "admin") {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      }
    },
    // 获取用户名
    getUsers() {
      user.loginUserInfo().then(res => {
        this.getUserRight(res.data.data.userName)
      })
    },

    rowStyle({ row, rowIndex }) {
      let stylejson = {}
      if (this.alarmOrfaultDeviceId != undefined) {
        if (this.alarmOrfaultDeviceId == row.deviceId) {
          stylejson.backgroundColor = '#ffa8a8'
          stylejson.color = '#000'
          return stylejson
        } else {
          return ''
        }
      }
    },

    goSelect() {
      this.$router.go(-1)
    },

    // 查所有公司和系统
    async getAllCompanyAndSystem() {
      const { data: company } = await companySystem.getCompanyInfo()
      this.allCompanyList = company.data.list;
      console.log(this.allCompanyList);
      const { data: system } = await noticeSysManagement.getNotifyBigSystemById()
      this.allSystemList = system.data.list;

      for (let i = 0; i < this.allCompanyList.length; i++) {
        if (this.allCompanyList[i].id == this.$route.query.companyId) {
          this.companyName = this.allCompanyList[i].name
        }
      }
      try{
        const { data: nameList } = await DeviceHistoryStatus.getToDistributionPersonList()
        this.nameList = nameList.data.list
      // this.getAlldevice();
      }catch(error){
        console.log("获取联系人失败！");
      }
    },
    // 查全部设备
    getAlldevice() {
      this.getAlarm();
      this.getFault();
    },

    async getAlarm() {

      // 报警记录
      const { data: alarm } = await DeviceHistoryStatus.getAllAlarmUntreated(this.alarmCurrent, this.alarmLimit, this.deviceStatus);
      this.alarmList = alarm.data.alarmList;
      this.alarmTotal = alarm.data.alarmTotal

      //   this.alarmList.process == null ? this.alarmList.process = '-' : this.alarmList.process

      //   设备信息 默认绑定为报警设备的第一个
      if (this.alarmList.length == 0) {
        this.thisDevice.title = '设备信息'
        this.thisDevice.id = ''
      } else {
        this.thisDevice.title = this.alarmList[0].typeName
        this.thisDevice.id = this.alarmList[0].deviceId
        this.processList = this.alarmList[0]

        this.getDeviceInfoById()
      }
      //修改公司id为公司名称
      for (const i in this.alarmList) {
        if (this.alarmList[i].process == null) {
          this.alarmList[i].process = '—'
        }
        if (this.alarmList[i].processType == null) {
          this.alarmList[i].processType = '—'
        }
        if (this.alarmList[i].checkTime == null) {
          this.alarmList[i].checkTime = '—'
        }
        let result = this.isExistCompanyId(this.allCompanyList, this.alarmList[i]["companyId"])
        this.alarmList[i]["companyId"] = result
      }
    },

    async getFault() {
      //   故障记录
      const { data: fault } = await DeviceHistoryStatus.getAllFaultUntreated(this.faultCurrent, this.faultLimit, this.deviceStatus);
      this.faultList = fault.data.faultList;
      this.faultTotal = fault.data.faultTotal
      //修改公司id为公司名称
      for (const i in this.faultList) {
        if (this.faultList[i].process == null) {
          this.faultList[i].process = '—'
        }
        if (this.faultList[i].processType == null) {
          this.faultList[i].processType = '—'
        }
        if (this.faultList[i].checkTime == null) {
          this.faultList[i].checkTime = '—'
        }
        let result = this.isExistCompanyId(this.allCompanyList, this.faultList[i]["companyId"])
        this.faultList[i]["companyId"] = result
      }
    },
    //判断是否存在匹配的元素，若存在就返回对应名称，不存在则返回无
    isExistCompanyId(list, str) {
      for (let i = 0; i < list.length; i++) {
        if (list[i]["id"] == str) {
          return list[i]["name"];
        }
      }
      return "无";
    },

    //获取所有设备类型
    async getAllDeviceType() {
      const { data: res } = await DeviceTypeManagement.getDeviceTypeByUser()
      this.deviceTypeList = res.data.list;
    },

    // 查所有公司结构
    async getCompanyStructure() {
      const { data: res } = await CompanyInfo.getAllCompanyStructure()
      this.companyList = res.data.list;
      let com = this.companyList
      //   let size = com.length;
      for (let i = 0; i < com.length; i++) {
        let isDelete = this.isNotCompany(com[i], i)
        if (isDelete == true) {
          com.splice(i, 1)
          i--
        } else if (isDelete != undefined && isDelete != null && isDelete != "") {
          com[i] = isDelete
        }
      }
    },
    isNotCompany(obj, index) {
      if (obj.name.indexOf("部门") != -1 || obj.name.indexOf("系统") != -1) {
        return true;
      } else if (obj.smallCompanyList.length > 0) {
        // let size = obj.smallCompanyList.length
        for (let i = 0; i < obj.smallCompanyList.length; i++) {
          let isDelete = this.isNotCompany(obj.smallCompanyList[i], i)
          if (isDelete == true) {
            obj.smallCompanyList.splice(i, 1)
            i--
          } else if (isDelete != undefined && isDelete != null && isDelete != "") {
            obj.smallCompanyList[i] = isDelete
          }
        }
        // return obj;
      } else {
        // return obj;
      }
    },

    // 选择公司过滤器
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    // 获取公司节点信息   (根据公司查全部设备)
    getPickCompant(value) {
      // this.$refs.tree.setCheckedKeys(this.$route.query.companyId)
      if (value == undefined) {
        this.checkedKeys = []
        this.checkedKeys.push(this.$route.query.companyId)
        // this.checkedKeys = this.$refs.tree.getCheckedKeys();
        this.deviceStatus.ciIds = this.checkedKeys
        this.getAlldevice();
      } else {
        this.checkedKeys = this.$refs.tree.getCheckedKeys();
        this.deviceStatus.ciIds = this.checkedKeys
        this.getAlldevice();
      }
    },

    // 分页
    pagination(msg) {
      if (msg == 'alarm') {
        this.getAlarm()
      } else {
        this.getFault()
      }
    },

    pickedTime(value) {
      if (value == null) {
        this.deviceStatus.startTime = '';
        this.deviceStatus.endTime = '';
      } else {
        let startTime = this.$moment(value[0]).format('YYYY-MM-DD HH:mm:ss')
        let endTime = this.$moment(value[1]).format('YYYY-MM-DD HH:mm:ss')
        this.deviceStatus.startTime = startTime;
        this.deviceStatus.endTime = endTime;
      }
    },

    // 查询按钮
    queryInformation() {
      this.getAlldevice();
    },

    async rowClick(value) {
      this.thisTypeId = value.typeId
      this.rowInfo = value;
      this.currentStatus = value.handleStatus
      this.thisDevice.id = value.deviceId
      this.thisDevice.title = value.typeName
      this.processList = value;

      if (value.typeId.indexOf('QrCode') != -1) {
        this.showQrCodePhone = true     // 如果是二维码设备，显示上报电话
        const res = await DeviceHistoryStatus.getPhoneByQrScanAlarmId(value.id)
        this.qrCodePhone = res.data.data.phone
      } else {
        this.showQrCodePhone = false
      }
      this.getDeviceInfoById(value);
    },
    // 根据设备id查设备信息
    getDeviceInfoById(value) {
      this.deviceInfo = [];
      this.deviceImg = ''
      DeviceInfoManagement.getDeviceInfoById(this.thisDevice.id).then(res => {
        if (res != undefined) {
          this.undefd = false;
          this.deviceInfos = res.data.data.deviceInfoVo
          this.deviceImgList = [];
          for (const val of this.deviceInfos.device) {
            if (this.deviceInfos.typeName.indexOf('烟感') != -1) {
              this.deviceImg = this.baseUrl + "deviceInfoIcon/电信无线烟感.png"
              this.deviceImgList.push(this.deviceImg)
            } else if (val.proName == "设备图片") {
              this.deviceImg = this.baseUrl + val.value
              this.deviceImgList.push(this.deviceImg)
            }
          }
          for (let i = 0; i < res.data.data.deviceInfoVo.name.length; i++) {
            let obj = {}
            obj.name = res.data.data.deviceInfoVo.name[i]
            obj.value = res.data.data.deviceInfoVo.value[i]
            this.deviceInfo.push(obj)
          }

          // 设备属性 id 转 文字
          for (let i in this.deviceInfo) {
            if (this.deviceInfo[i]['name'] == "布防状态") {
              this.deviceInfo[i]['value'] == '1' ? this.deviceInfo[i]['value'] = '布防' : this.deviceInfo[i]['value'] = '不布防'
            }

            if (this.deviceInfo[i]['name'] == "所属系统") {
              this.deviceInfo[i]['name'] = "系统"
              let isChange = false;
              for (let j in this.allSystemList) {
                if (this.deviceInfo[i]['value'] == this.allSystemList[j]['id']) {
                  this.deviceInfo[i]['value'] = this.allSystemList[j]['bigSystemName']
                  isChange = true
                }
              }
              if (isChange == false) {
                this.deviceInfo[i]['value'] = "无"
              }
            }

            if (this.deviceInfo[i]['name'] == "绑定公司") {
              this.deviceInfo[i]['name'] = "单位"
              let ciId = this.deviceInfo[i]['value']
              let result = this.isExistCompanyId(this.allCompanyList, this.deviceInfo[i]['value'])
              this.deviceInfo[i]['value'] = result
              //通过公司id获取公司的详细信息,并把单位的电话显示在页面上
              if (ciId != "") {
                let obj = {}
                obj.ciId = ciId
                CompanyMessage.getCompanyMessage(obj).then(res => {
                  let obj = {}
                  obj.name = "单位电话"
                  if (res.data.data.companyMessage.phone != "" && res.data.data.companyMessage.phone != null && res.data.data.companyMessage.phone != undefined) {
                    obj.value = res.data.data.companyMessage.phone
                  } else {
                    obj.value = "无"
                  }
                  this.deviceInfo.push(obj)
                })
              }
            }
          }
          // res == undefined  把设备编号、设备类型、单位、报警时间显示在左下角
        } else {
          this.undefd = true;
          let ciId = ''
          for (const i in this.allCompanyList) {
            if (value.companyId === this.allCompanyList[i].name) {
              ciId = this.allCompanyList[i].id
            }
          }
          this.deviceInfos.id = value.deviceId
          let obj = {}
          obj.ciId = ciId
          CompanyMessage.getCompanyMessage(obj).then(res => {
            let obj = {}
            obj.name = "单位电话"
            if (res.data.data.companyMessage.phone != "" && res.data.data.companyMessage.phone != null && res.data.data.companyMessage.phone != undefined) {
              obj.value = res.data.data.companyMessage.phone
            } else {
              obj.value = "无"
            }
            this.deviceInfo.push(obj)
          })
          console.log(this.deviceInfo);
        }
      })
    },

    // 一键处理
    aKeyProcess() {
      this.symbol = '一键处理'
      if (this.alarmObj.value.length == 0 && this.faultObj.value.length == 0) {
        this.$message.warning('请选择处理设备')
      } else {
        this.visible = true
        console.log(this.alarmObj);
        this.idStr = ''
        let arr = []
        for (let i = 0; i < this.alarmObj.value.length; i++) {
          arr.push(this.alarmObj.value[i].id)
        }
        for (let i = 0; i < this.faultObj.value.length; i++) {
          arr.push(this.faultObj.value[i].id)
        }
        this.idStr = arr.toString()
        console.log(this.idStr);
      }
    },
    // this.$confirm("此操作将一键处理所有异常设备, 是否继续?", "提示", {
    //   confirmButtonText: "确定",
    //   cancelButtonText: "取消",
    //   type: "warning",
    // }).then(() => {
    //   let obj = {}
    //   if (this.deviceStatus.deviceId != "") {
    //     obj.deviceId = this.deviceStatus.deviceId
    //   }
    //   if (this.deviceStatus.typeId != "") {
    //     obj.deviceTypeId = this.deviceStatus.typeId
    //   }
    //   if (this.deviceStatus.startTime != "" && this.deviceStatus.endTime != "") {
    //     obj.startTime = this.deviceStatus.startTime
    //     obj.endTime = this.deviceStatus.endTime
    //   }
    //   DeviceHistoryStatus.updateRecord(obj).then(res => {
    //     if (res.data.message == "处理成功") {
    //       this.$message({
    //         message: "处理成功！",
    //         type: "success",
    //       });
    //       this.getAlldevice();
    //     } else {
    //       this.$message({
    //         message: "处理失败！",
    //         type: "error",
    //       });
    //     }
    //   })
    // });



    async showVideo() {
      if (this.deviceInfos.typeId == "YSVideo") {
        this.$router.push({
          path: '/VideoPlayback',
          query: {
            rowInfo: this.deviceInfos,
          }
        });
      }
      else if (this.deviceInfos.isBindRTSP) {
        //根据设备Id获取绑定视频的Id、视频绑定的网关IP、端口、视频通道。。
        const res = await DeviceInfoManagement.getRTSPByDeviceId(this.deviceInfos.videoInfoId1);
        console.log(res);
        this.playUrl = res.data.data.playUrl;
        //当前页弹窗播放
        if (this.playUrl != "") {
          this.playerDialogVisible = true;
          this.$nextTick(() => {
            console.log(this.$refs["video0"]);
            this.$refs["video0"].createPlayer(this.playUrl, false, "0");
          })
        } else {
          this.$message({
            type: "error",
            message: "获取rtsp地址失败!",
          });
        }
      }
      else if (this.deviceInfos.isBindFlv) {
        //根据设备Id获取绑定视频的Id、视频绑定的网关IP、端口、视频通道。。
        await this.getBindVideoMessageByDeviceId(this.deviceInfos.id)
        //当前页弹窗播放
        if (this.playUrl != "") {
          this.playerDialogVisible = true;
          this.$nextTick(() => {
            console.log(this.$refs["video0"]);
            this.$refs["video0"].createPlayer(this.playUrl, false, "0");
          })
        } else {
          this.$message({
            type: "error",
            message: "获取Flv地址失败!",
          });
        }
      }
      else if (this.deviceInfos.typeId == "VideoRTSP") {

      }
      else if (this.deviceInfos.typeId == "") {

      }
      else if (this.deviceInfos.typeId == "video") {
        const { data: res1 } = await HomePage.viewVideo(this.deviceInfos.id)
        if (res1.data.videoUrl != null) {
          window.location.href = res1.data.videoUrl;
        } else {
          this.$message({
            type: "error",
            message: "该设备未绑定任何摄像头！",
          });
        }
      }
      else if (this.deviceInfos.videoUrl) {
        window.location.href = this.deviceInfos.videoUrl;
      }
      else {
        this.$message({
          type: "warning",
          message: "该设备未绑定视频设备！",
        });
      }
    },
    handleClick() {
      // 处理单个设备
      if (this.symbol == '处理') {
        if (this.currentStatus.indexOf('已处理') != -1) {
          this.$message.warning("该设备已处理，请勿重复操作！")
        } else {
          this.visible = false;
          let isSolve = true;
          if (this.processRadio === 1) {
            this.processList.processType = "真实";
          } else if (this.processRadio === 2) {
            this.processList.processType = "误报";
          } else if (this.processRadio === 3) {
            this.processList.processType = "检修";
          } else if (this.processRadio === 4) {
            this.processList.processType = "测试";
          } else {
            isSolve = false;
            this.visible = true;
            this.$message.error("请选择处理类型！");
          }
          if (isSolve) {
            this.textarea == '' ? this.textarea = '无' : this.textarea
            this.processList.process = this.textarea
            this.processList.checkTime = ''

            console.log(this.processList)
            let obj = {}
            obj.id = this.processList.id

            obj.deviceId = this.processList.deviceId

            if (this.processList.process != "" && this.processList.process != "无") {
              obj.process = this.processList.process
            }

            obj.processType = this.processList.processType
            DeviceHistoryStatus.updateRecord(obj).then(res => {
              if (res.data.message == "处理成功") {
                this.$message({
                  message: "处理成功！",
                  type: "success",
                });
                this.getAlldevice();
              } else {
                this.$message({
                  message: "处理失败！",
                  type: "error",
                });
              }
            })
          }
          // 处理完清空
          this.processRadio = ''
          this.textarea = ''

        }

        // 复选框一键处理
      } else {
        //对选择处理类型的提示
        if (this.processRadio == '' || this.textarea == '') {
          this.$message.warning('填写处理信息')
        } else {
          if (this.processRadio === 1) {
            this.processList.processType = "真实";
          } else if (this.processRadio === 2) {
            this.processList.processType = "误报";
          } else if (this.processRadio === 3) {
            this.processList.processType = "检修";
          } else if (this.processRadio === 4) {
            this.processList.processType = "测试";
          }
          // this.processList.process = this.textarea
          // this.processList.id = this.idStr
          let obj = {}
          obj.id = this.idStr
          obj.process = this.textarea
          obj.processType = this.processList.processType
          console.log(obj);
          DeviceHistoryStatus.updateRecord(obj).then(res => {
            console.log(res);
            if (res.data.message == "处理成功") {
              this.$message({
                message: "处理成功！",
                type: "success",
              });
              this.getAlldevice();
            } else {
              this.$message({
                message: "处理失败！",
                type: "error",
              });
            }
          })
            // 处理完清空
            this.processRadio = ''
          this.textarea = ''
          this.visible =false
        }
      }

    },
    toCRT() {
    crtDeviceCoordinate.getCoordinateByDeviceId(this.deviceInfos.id).then((res) => {
      if (res.data.message == '失败') {
        this.$message({
          type: "warning",
          message: "该设备尚未添加点位！",
        });
      } else {
        this.$router.push({
          name: "CRTShow",
          params: {
            CRTId: res.data.data.data.crtPictureId,
            deviceId: res.data.data.data.deviceInfoId,
          },
        });
      }
    });
  },
//根据设备ID查询绑定的视频id，通道，视频网关ip，网关端口
async getBindVideoMessageByDeviceId(deviceId) {
    const res = await deviceInfo.getBindVideoMessageByDeviceId(deviceId)
    if (res) {
      //8082是网关推流端口，基本写死，和网关设备上的port（res.data.data.gatewayPort）不是同一属性
      this.playUrl = "http://" + res.data.data.gatewayIp + ":8082/" + res.data.data.videoChannel + ".flv";
    }
  },
  //开始播放
  handlePlay() {
    if (this.playUrl) {
      this.$refs[CusPlayer].createPlayer(this.playUrl, true, 0);
    } else {
      this.$message.error("请填写播放地址");
    }
  },
  }}
</script>
<style lang="scss" scoped>
.container {
  margin-top: 30px;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;

  .left {
    width: 17.7%;
    height: 95%;
    box-sizing: border-box;
    margin: 0 15px;

    // padding: 15px;
    // border-radius: 10px;
    .left-content-top {
      border-radius: 10px;
      margin-top: 5px;
      width: 100%;
      height: 46.8%;
      overflow: scroll;
      background: rgba(1, 65, 70, 0.2);
      scrollbar-width: none;
      // background: #fff;
    }

    .left-content-btm {
      scrollbar-width: none;

      background: rgba(1, 65, 70, 0.2);
      box-sizing: border-box;
      height: 46.8%;
      margin-top: 10px;
      border-radius: 10px;
      overflow: scroll;

      //   设备图片
      .img-box {
        width: 100%;
        height: 30%;
        box-sizing: border-box;
        // border: 1px solid red;

        .img-cont {
          //   width: 100%;
          height: 100%;

          .img {
            // width: 180px;
            height: 100%;
            margin: 0px 30px;
            padding: 10px 15px;
            display: inline-block;
          }
        }
      }

      // 点击行数展示的设备信息样式 （左下）
      /deep/.el-descriptions__title {
        font-size: 20px;
        color: #0cc7ce;
      }

      /deep/.el-descriptions__title::before {
        content: "丨";
        font-weight: 600;
        font-size: 20px;
        color: #0cc7ce;
      }

      /deep/.el-descriptions__title::after {
        content: "";
        font-size: 20px;
      }
    }
  }

  //   模块标题
  .type-title {
    margin: 10px;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: #0cc7ce;
      letter-spacing: 2px;
    }

    .title::before {
      content: "丨";
      font-weight: 600;
      font-size: 20px;
    }
  }

  .right {
    width: 80%;

    .content1 {
      box-sizing: border-box;
      padding: 0px 10px 0 0;
      height: 44.5%;
      margin: 10px 0;
      border-radius: 10px;
      background: rgba(1, 65, 70, 0.2);

      // background: #fff;

      .content1-item {
        // border: 1px solid red;
        height: 90%;
        // height: 400px;
      }

      .content1-item1 {
        position: relative;
        // border: 1px solid green;
        // height: 200px;
        height: 10%;
        // height: 40px;
        // background: #fff;
        // height: 40px;

        .row {
          position: absolute;
          bottom: -10px;
          padding-left: 32%;
          // height: 40px;
        }
      }
    }
  }
}

/deep/ .el-table {
  scrollbar-width: none !important;
}

::-webkit-scrollbar {
  display: none;
  // height: 94%;
}

/deep/.el-table .el-table__cell {
  padding: 8px 0;
}

// el-table 表格 透明样式   ################################################
/deep/ .el-table,
/deep/ .el-table__expanded-cell {
  background-color: transparent;
}

/* 表格内背景颜色 */
/deep/ .el-table th,
/deep/ .el-table tr,
/deep/ .el-table td {
  background-color: transparent;
}

/deep/::-webkit-scrollbar {
  display: none;
}

/deep/ .el-table th.el-table__cell>.cell {
  font-size: 16px;
  font-weight: 600;
  //   color: rgb(62, 177, 243);
}

/deep/.el-table__body,
.el-table__footer,
.el-table__header {
  font-size: 15px;
  //   color: rgb(62, 177, 243);
}

/deep/.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: none;
}

/deep/ th.el-table__cell.is-leaf {
  border: none;
}

/deep/.el-table tr {
  font-size: 15px;
  color: #80ecf0;
  //   color: #fff;
}

/deep/.el-table__row:hover {
  color: #035e61;
}

// /deep/.current-row:hover{
//     color: #035e61;
// }

/deep/.el-table::before {
  height: 0px;
}

/deep/.el-table__fixed::before {
  height: 0px;
}

/deep/.el-table__fixed-right::before {
  height: 0px;
}

/deep/.el-table__empty-text {
  margin-top: 80px;
  border: none;
  font-size: 16px;
  letter-spacing: 3px;
}

// 设备信息描述框   ##########################################################################

/deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
  border: none;
  background: rgba(0, 23, 45, 1);
  color: #0cc7ce;
  font-size: 15px;
}

// 树结构 ##################################################################
// /deep/.el-icon-caret-right:before {
//   color: #035e61;
// }
/deep/.el-tree-node__content {
  height: 30px;
  font-size: 15px;
  line-height: 30px;
}

.el-tree {
  color: #80ecf0;
  background: none;
}

/deep/.el-tree-node__content:hover {
  //   background: rgb(48, 79, 145);
  background: #fff;
  color: #035e61;
  font-weight: 600;
  border-radius: 3px;
}

// 分页 #########################################################
/deep/.el-pagination {
  bottom: 0px;
  height: 40px;
}

/deep/.el-pagination__total {
  color: #fff;
  font-weight: 600;
}

/deep/.el-pagination__jump {
  color: #fff;
  font-weight: 600;
}

/deep/.el-pager li {
  background: rgba(1, 79, 85, 0.1);
  color: #fff;
}

/deep/.btn-prev {
  background: rgba(1, 79, 85, 0);
  color: #fff;
}

/deep/.el-pagination button:disabled {
  background: none;
  color: #fff;
}

// ######################
/deep/.btn-next {
  background: none;
  color: #fff;
}

/deep/.el-input__inner {
  background: rgba(1, 79, 85, 0.3);
  color: #fff;
  border: none;
}

/deep/.el-range-input {
  background: none;
}

/deep/.el-table__row {
  background-color: red;
}

/deep/.el-date-editor .el-range-input {
  color: #fff;
}

// 图片加载失败
/deep/.el-image__error {
  width: 180;
  background: rgba(1, 79, 85, 0.3);
}

// 复选框
/deep/.el-checkbox__inner {
  background: none;
  border: 1px solid rgba(32, 227, 241, 0.8);
}

/deep/.img-error {
  color: rgba(62, 191, 196, 0.747);
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  line-height: 100px;
  user-select: none;
}

/deep/.el-table--scrollable-x .el-table__body-wrapper {
  scrollbar-width: none;
}

/deep/.el-table--scrollable-y .el-table__body-wrapper {
  scrollbar-width: none;
}

.assign-box {
  width: 100%;

  /deep/.el-input__inner {
    background: #fff;
    color: #aaa;
    border: 1px solid rgb(221, 217, 217);
    width: 100%;
  }
}

.del {
  cursor: pointer;
  padding: 5px 0 5px 20px;
  border-radius: 3px;
}

.del:hover {
  background: rgba(1, 65, 70, 0.1);
}

.del-item:hover {
  color: red;
}
// HOVER修改
/deep/.el-table__row:hover {
  color: #72d4dc;
  }
/deep/.el-table__body .el-table__row.hover-row td{
  background-color:rgba($color: #007a91, $alpha: 0.5) !important;
}
/deep/ .el-table__body tr.current-row > td {
  background-color:rgba($color: #007a91, $alpha: 0.5) !important;
}
</style>