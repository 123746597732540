import request from '@/../utils/request'
const api_name = '/iotechserver/crtDeviceCoordinate'

export default {
    addCrtDeviceCoordinate(crtDeviceCoordinateInfo) {
        return request({
            url: `${api_name}/addCrtDeviceCoordinate`,
            method: 'post',
            data: crtDeviceCoordinateInfo
        })
    },
    getCoordinateByCrtId(crtPictureId){
        return request({
            url: `${api_name}/getCoordinateByCrtId/${crtPictureId}`,
            method: 'get'
        })
    },
    removeCoordinateById(id){
        return request({
            url: `${api_name}/removeCoordinateById/${id}`,
            method: 'delete'
        })
    },
    updateIconSize(crtDeviceCoordinateInfo){
        return request({
            url: `${api_name}/updateIconSize`,
            method: 'post',
            data: crtDeviceCoordinateInfo
        })
    },

    getCoordinateByDeviceId(deviceId){
        return request({
            url: `${api_name}/getCoordinateByDeviceId/${deviceId}`,
            method: 'get'
        })
    },

}