import request from '../../../utils/request'
const api_name = '/iotechserver/deviceInfo'

export default {

  getDeviceInfoList(current, limit, deviceInfoQuery) {
    return request({
      url: `${api_name}/pageDeviceInfo`,
      method: 'post',
      data: {
        current: current,
        limit: limit,
        id: deviceInfoQuery.id,
        typeName: deviceInfoQuery.typeName,
        ciId: deviceInfoQuery.ciId
      }
    })
  },

deriveTable(){
  return request({
    url: `${api_name}/deriveTable`,
    method:'post',
  })
},

 // 获取水表状态
 checkEquipmentStatus(deviceIds){
  return request({
      url:`${api_name}/checkEquipmentStatus`,
      method:'post',
      data: deviceIds,
    })
},

  getCompanyEqType(id) {
    return request({
      url: `${api_name}/getCompanyEqType/${id}`,
      method: 'get',
    })
  },
  getCompanyEqTypes(id, typeId) {
    return request({
      url: `${api_name}/getCompanyEqTypes/${id}/${typeId}`,
      method: 'get',
      // data: deviceInfoQuery
    })
  },
  addDeviceInfo(deviceInfo) {
    return request({
      url: `${api_name}/addDeviceInfo`,
      method: 'post',
      data: deviceInfo
    })
  },
  saveDeviceImg(formData) {
    return request({
      url: `${api_name}/saveDeviceImg`,
      method: 'post',
      data: formData
    })
  },
  removeDeviceInfo(id) {
    return request({
      url: `${api_name}/removeDeviceInfo/${id}`,
      method: 'delete',
    })
  },
  updataDeviceInfo(deviceInfo) {
    return request({
      url: `${api_name}/updataDeviceInfo`,
      method: 'post',
      data: deviceInfo
    })
  },
  getNoCoordinateDeviceInfoList(current, limit, deviceInfoQuery) {
    return request({
      url: `${api_name}/getNoCoordinateDeviceInfoList/${current}/${limit}`,
      method: 'get',
      params: deviceInfoQuery
    })
  },
  getDeviceInfoByDeviceType(deviceInfoQuery) {
    return request({
      url: `${api_name}/getDeviceInfoByDeviceType`,
      method: 'get',
      data: deviceInfoQuery
    })
  },
  getDeviceInfoById(id) {
    return request({
      url: `${api_name}/getDeviceInfoById/${id}`,
      method: 'get'
    })
  },
  getAddedDeviceInfoList(deviceInfoQuery) {
    return request({
      url: `${api_name}/getAddedDeviceInfoList`,
      method: 'get',
      params: deviceInfoQuery
    })
  },
  getAllVideo(deviceInfoId) {
    return request({
      url: `${api_name}/getAllVideo/${deviceInfoId}`,
      method: 'get'
    })
  },
  bindVideo(info) {
    return request({
      url: `${api_name}/bindVideo`,
      method: 'post',
      data: info
    })
  },
  getDeviceInfoValueByDeviceId(id) {
    return request({
      url: `${api_name}/getDeviceInfoValueByDeviceId/${id}`,
      method: 'get'
    })
  },
  getAllDeviceTypeAboutMap() {
    return request({
      url: `${api_name}/getAllDeviceTypeAboutMap`,
      method: 'get'
    })
  },
  // 设备实时值
  getDeviceInfo() {
    return request({
      url: `${api_name}/getDeviceInfo`,
      method: 'post'
    })
  },
  //获取设备历史记录显示折线图
  getDayValue(deviceId, value, createTime, typeId, valueName) {
    return request({
      url: '/device-history-value/getDayValue',
      method: 'post',
      data: {
        deviceId: deviceId,
        value: value,
        createTime: createTime,
        typeId: typeId,
        valueName: valueName
      }
    })
  },
  //获取设备的动态属性
  getDynamicPro(typeId) {
    return request({
      url: '/iotechserver/deviceTypeProAndVal/getDynamicPro',
      method: 'post',
      data: {
        typeId: typeId
      }
    })
  },
  //根据设备id查询设备下的设备并返回设备信息
  getDeviceInfoByDeviceId(id, current, limit) {
    return request({
      url: `${api_name}/getDeviceInfoByDeviceId/${id}/${current}/${limit}`,
      method: 'get'
    })
  },
  //查询aep设备的状态
  queryAEPDeviceStatus(typeId, deviceId) {
    return request({
      url: `${api_name}/queryAEPDeviceStatus`,
      method: 'post',
      data: {
        typeId: typeId,
        deviceId: deviceId
      }
    })
  },


  // 查公司及公司下的设备
  getDeviceListByCiIdStructure(typeId) {
    let url = "";
    if (typeId == undefined || typeId == null || typeId == "") {
      url = `${api_name}/getDeviceListByCiIdStructure/null`
    } else {
      url = `${api_name}/getDeviceListByCiIdStructure/${typeId}`
    }
    return request({
      url: url,
      method: 'get',
    })
  },

  // 查顶级公司
  getTopCiIds() {
    return request({
      url: `${api_name}/getTopCiIds`,
      method: 'get'
    })
  },
  // 根据公司ID查公司和设备
  getDeviceListByCiId(ciId, typeId) {
    return request({
      url: `${api_name}/getDeviceListByCiId/${ciId}/${typeId}`,
      method: 'get',
    })
  },


  // 查询所有设备
  getDeviceIdList(deviceType) {
    return request({
      url: `${api_name}/getDeviceIdList/${deviceType}`,
      method: 'get',
    })
  },

  //根据设备ID查询RTSP
  getRTSPByDeviceId(deviceId){
    return request({
      url: `${api_name}/getRTSPByDeviceId/${deviceId}`,
      method:'get',
    })
  },
  //根据设备ID查询RTSP
  getPlayUrlByDeviceId(deviceId){
    return request({
      url: `${api_name}/getPlayUrlByDeviceId/${deviceId}`,
      method:'get',
    })
  },
  //根据设备ID查询设备绑定的视频id，网关ip，通道，网关端口
  getBindVideoMessageByDeviceId(deviceId){
    return request({
      url: `${api_name}/getBindVideoMessageByDeviceId`,
      method:'post',
      data: {
        deviceId: deviceId
      }
    })
  },
  //根据flv视频设备id查询通道、网关ip、—— 够用了
  getVideoMessageByVideoNameByName(deviceId){
    return request({
      url: `${api_name}/getVideoMessageByVideoNameByName`,
      method:'post',
      data: {
        deviceId: deviceId
      }
    })
  },

}